import React, { useEffect } from "react";
import {
  getAllDocsSnap,
  addNewDoc,
  storage,
} from "../../utils/firebase/Firebase.utils";
import Modal from "./Components/Modal";
import InputBox from "./Components/InputBox";
import Label from "./Components/Label";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import toast from "react-hot-toast";

function Services() {
  const [activeService, setActiveService] = React.useState("");
  const [suppliers, setSuppliers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [inProcess, setInProcess] = React.useState(false);
  const [addMode, setAddMode] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [formData, setFormData] = React.useState({});

  useEffect(() => {
    const fetchSuppliers = async () => {
      await getAllDocsSnap("suppliers", (data) => {
        setSuppliers(data);
        setLoading(false);
      });
    };
    fetchSuppliers();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // const services = ["laundry", "cleaning", "water"];
  const services = [];

  const activeServiceStyle = "bg-green-500  ";

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInProcess(true);
    await addNewDoc("suppliers", formData);
    toast.success("Supplier added successfully");
    setInProcess(false);
    setAddMode(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `suppliers/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          setFormData((prev) => ({ ...prev, logo: downloadURL }));
        });
      }
    );
  };

  const handleServices = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, services: [value] }));
  };

  console.log(suppliers);
  console.log(formData);

  return (
    <div className="text-blue-900 p-2">
      <div className="flex items-center justify-between">
        <div className="font-bold text-lg ">Accredited Suppliers</div>
        <div className="flex items-center justify-normal gap-4"></div>

        <div className="flex items-center gap-2">
          <button className="blue-button" onClick={handleAddMode}>
            Add
          </button>
          <button className="blue-button">Edit</button>
        </div>
      </div>
      <div className="flex items-center justify-between border border-gray-300 px-4 py-2 text-xs">
        <div className="flex items-center justify-normal rounded-lg border border-gray-300 divide-x">
          {services.map((service, index) => (
            <div
              key={index}
              className={`p-2 cursor-pointer min-w-20 capitalize ${
                activeService === service ? activeServiceStyle : ""
              }`}
              onClick={() => setActiveService(service)}
            >
              {service}
            </div>
          ))}
        </div>
        <input
          type="search"
          placeholder="Search by Name"
          className="border border-gray-300 w-96 rounded-lg px-4 py-2"
        />
        <div>Filter</div>
      </div>

      <div className="flex items-start lg:justify-normal gap-4 mt-8 flex-wrap justify-center">
        {suppliers.map((supplier, index) => (
          <div
            key={index}
            className="border border-gray-300 w-60 h-60 p-4 rounded-lg bg-white flex flex-col items-center justify-center gap-2 text-sm text-gray-600 hover:border-blue-900 hover:border cursor-pointer"
          >
            <div>
              <img
                src={supplier.logo}
                className="w-24 h-24 object-contain"
                alt=""
              />
            </div>
            <div className="header">{supplier.name}</div>
            <div>{supplier.phone}</div>
            <div>{supplier.address}</div>
          </div>
        ))}
      </div>

      {addMode && (
        <div>
          <Modal>
            <form action="" onSubmit={handleSubmit} className="relative">
              <button
                type="button"
                className="close-button"
                onClick={handleAddMode}
              >
                Close
              </button>
              <div className="p-4">
                <div className="font-bold text-sm text-blue-900">
                  Add Supplier
                </div>
                <div className="mt-4">
                  <div className="flex items-center gap-4 flex-wrap">
                    <InputBox
                      label={"Name"}
                      type="text"
                      name="name"
                      id="name"
                      className="border border-gray-300 rounded-lg px-4 py-2"
                      onChange={handleChange}
                    />

                    <InputBox
                      label={"Type of Service"}
                      type="text"
                      name="services"
                      id="name"
                      className="border border-gray-300 rounded-lg px-4 py-2"
                      onChange={handleServices}
                    />
                    <InputBox
                      label={"Phone"}
                      type="text"
                      name="phone"
                      id="phone"
                      className="border border-gray-300 rounded-lg px-4 py-2"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mt-4">
                    <div>
                      <InputBox
                        label={"Address"}
                        type="text"
                        name="address"
                        id="address"
                        className="border border-gray-300 rounded-lg px-4 py-2"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <div>
                      <Label label={"Logo"}>
                        <input
                          type="file"
                          name="logo"
                          id="logo"
                          className="border border-gray-300 rounded-lg px-4 py-2"
                          onChange={handleUpload}
                        />
                        <div>{progress}%</div>
                      </Label>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button type="submit" className="blue-button">
                      {inProcess ? "Adding..." : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default Services;
