import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { getAllDocs } from "../../utils/firebase/Firebase.utils";
import RequestsCard from "./RequestsCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GatePassRequestCard from "./GatePassRequestCard";
import ConcernsRequestCard from "./ConcernsRequestCard";

function RequestSummary() {
  const [requests, setRequests] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const currentUser = useSelector((state) => state.userObject);
  const [activeLink, setActiveLink] = React.useState("workPermits");
  const [gatePasses, setGatePasses] = React.useState([]);
  const [concerns, setConcerns] = React.useState([]);

  const links = [
    {
      name: "Work Permits",
      link: "workPermits",
    },
    {
      name: "Gate Pass",
      link: "gatePass",
    },
    {
      name: "Facilities",
      link: "facilities",
    },
    {
      name: "Concerns",
      link: "concerns",
    },
  ];

  useEffect(() => {
    const fetchRequests = async () => {
      const data = await getAllDocs("concerns");
      //filter active requests by email
      const userRequests = data.filter(
        (request) => request.user === currentUser.email
      );
      setConcerns(userRequests);
    };
    fetchRequests();
  }, []);

  useEffect(() => {
    const fetchRequests = async () => {
      const data = await getAllDocs("workPermits");
      //filter active requests by email
      const userRequests = data.filter(
        (request) => request.createdBy === currentUser.email
      );
      setRequests(userRequests);
    };
    fetchRequests();
  }, []);

  useEffect(() => {
    const fetchGatePasses = async () => {
      const data = await getAllDocs("gatePass");
      //
      const userGatePasses = data.filter(
        (request) => request.user === currentUser.email
      );
      setGatePasses(userGatePasses);
      console.log(userGatePasses);
      setIsLoading(false);
    };
    fetchGatePasses();
  }, []);

  if (isLoading) {
    return (
      <UserScreenWindow label="Requests Summary">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const activePillStyle =
    "bg-blue-900 text-white px-2 py-1 rounded-full text-xs text-center mt-4 w-fit";

  const inactivePillStyle =
    "bg-gray-300 text-gray-600 px-2 py-1 rounded-full text-xs text-center mt-4 w-fit cursor-pointer";

  console.log(requests);

  return (
    <UserScreenWindow label={"Requests Summary"}>
      <div className="font-bold text-blue-900 text-center mt-8 text-sm">
        Requests Summary
      </div>

      <div className="flex items-center justify-start space-x-2 flex-wrap">
        {links.map((link, index) => (
          <div
            key={index}
            onClick={() => setActiveLink(link.link)}
            className={
              activeLink === link.link ? activePillStyle : inactivePillStyle
            }
          >
            {link.name}
          </div>
        ))}
      </div>

      {activeLink === "workPermits" && (
        <div className="space-y-2 shadow-sm mt-4 ">
          {requests.map((request, index) => (
            <div key={index}>
              <Link
                to={`/request-details/${request.id}`}
                className="cursor-pointer"
              >
                <RequestsCard request={request} />
              </Link>
            </div>
          ))}
        </div>
      )}

      {activeLink === "gatePass" && (
        <div className="space-y-2 shadow-sm mt-4 ">
          {gatePasses.map((request, index) => (
            <div key={index}>
              <Link
                to={`/gatepass-details/${request.id}`}
                className="cursor-pointer"
              >
                <GatePassRequestCard gatepass={request} />
              </Link>
            </div>
          ))}
        </div>
      )}

      {activeLink === "concerns" && (
        <div className="space-y-2 shadow-sm mt-4 ">
          {concerns.map((request, index) => (
            <div key={index}>
              <Link to={`/concern/${request.id}`} className="cursor-pointer">
                <ConcernsRequestCard concern={request} />
              </Link>
            </div>
          ))}
        </div>
      )}
    </UserScreenWindow>
  );
}

export default RequestSummary;
