import UserScreenWindow from "./UserScreenWindow";
import Select from "react-select";
import { useEffect, useState } from "react";
import {
  getDocDataSnap,
  storage,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import toast from "react-hot-toast";

function WorkPermitUser() {
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [identifiers, setIdentifiers] = useState([]);
  const [files, setFiles] = useState([]);
  const [assignedId, setAssignedId] = useState(v4());
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState("");
  const [activeUpload, setActiveUpload] = useState(false);
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getUser = async () => {
      const userSnap = getDocDataSnap("users", currentUser.uid, (data) => {
        setIdentifiers(data.linkedIdentifiers);
      });
    };
    getUser();
  }, []);

  const options = [
    { value: "minor work", label: "Minor Work and Repairs " },
    { value: "major work", label: "Major Renovation" },
    { value: "construction", label: "House or Fence Construction" },
  ];

  const handleSelect = (e, name) => {
    setFormData({ ...formData, [name]: e.value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSettingFileList = (e, fileName) => {
    setFiles([...files, { name: fileName, file: e.target.files[0] }]);
  };

  const handleUpload = (e, fileName) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `workPermits/${assignedId}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setActiveUpload(fileName);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          setFiles([...files, { name: fileName, url: downloadURL }]);
          setActiveUpload(false);
        });
      }
    );
  };

  console.log(files);
  console.log(formData);

  const handleSubmit = async () => {
    setIsProcessing(true);
    const docObject = {
      ...formData,
      status: "Pending",
      name: currentUser.displayName,
      files: files,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.email,
      status: "pending",
      user: currentUser.uid,
    };

    await setDocData("workPermits", assignedId, docObject);
    navigate("/work-permit-confirmation/" + assignedId);
  };

  return (
    <UserScreenWindow label="Work Permit Request">
      <div>
        <div className="text-center font-bold mt-4 text-blue-900">
          Work Permits
        </div>
        <div>
          <label className="font-bold text-gray-600 text-xs">
            Choose Type of Work Permit Application
          </label>
          <Select
            options={options}
            className="text-xs"
            onChange={(e) => handleSelect(e, "workPermitType")}
          />
        </div>

        {formData.workPermitType === "major work" && (
          <div className="p-2 text-xs text-gray-600">
            Major Renovation: Additional new structures, major repairs, changes
            in current structure of the house.
          </div>
        )}

        <div>
          <label className="font-bold text-xs text-gray-600">
            Select House Number
          </label>
          <Select
            options={identifiers.map((id) => ({ value: id, label: id }))}
            className="text-xs"
            onChange={(e) => handleSelect(e, "identifier")}
          />
        </div>

        <div className="flex flex-col items-start mt-2">
          <label htmlFor="" className="font-bold text-xs text-gray-600">
            Duration of Activities
          </label>
          <input
            type="number"
            className="w-full rounded-lg border border-gray-100 p-2 text-sm"
            placeholder="Indicate number of Days"
            onChange={handleChange}
            name="duration"
          />
        </div>

        <div>
          <label className="font-bold text-xs text-gray-600">
            Provide a description of work to be done
          </label>
          <textarea
            className="w-full rounded-lg border border-gray-100 p-2 text-sm"
            rows={5}
            onChange={handleChange}
            placeholder="Description"
            name="description"
          />
        </div>

        <div>
          <label className="font-bold text-xs text-gray-600">
            Provide names of workers
          </label>
          <textarea
            className="w-full rounded-lg border border-gray-100 p-2 text-sm"
            rows={5}
            onChange={handleChange}
            placeholder="Workers List"
            name="workers"
          />
        </div>

        <div className="flex flex-col items-start mt-2">
          <label htmlFor="" className="font-bold text-xs text-gray-600">
            Vehicle Information of Workers/Contractor
          </label>
          <input
            type="text"
            className="w-full rounded-lg border border-gray-100 p-2 text-sm"
            placeholder="Indicate vehicle type and plate number"
            onChange={handleChange}
          />
        </div>

        {formData.workPermitType === "major work" && (
          <div className="mt-4">
            <div>
              <label className="font-bold text-xs text-gray-600">
                Upload Renovation Plan
              </label>
              <input
                type="file"
                onChange={(e) => handleUpload(e, "Renovation Plan")}
              />
              {activeUpload === "Renovation Plan" && (
                <progress value={progress} max="100"></progress>
              )}
            </div>

            <div>
              <label className="font-bold text-xs text-gray-600">
                Upload Renovation Permit
              </label>
              <input
                type="file"
                onChange={(e) => handleUpload(e, "Renovation Permit")}
              />
              {activeUpload === "Renovation Permit" && (
                <progress value={progress} max="100"></progress>
              )}
            </div>
          </div>
        )}

        {formData.workPermitType === "construction" && (
          <div className="mt-4">
            <div>
              <label className="font-bold text-xs text-gray-600">
                Upload Construction Plans
              </label>
              <input
                type="file"
                onChange={(e) => handleUpload(e, "Construction Plans")}
              />
              {activeUpload === "Construction Plans" && (
                <progress value={progress} max="100"></progress>
              )}
            </div>

            <div>
              <label className="font-bold text-xs text-gray-600">
                Upload Construction Permit
              </label>
              <input
                type="file"
                onChange={(e) => handleUpload(e, "Construction Permit")}
              />
              {activeUpload === "Construction Permit" && (
                <progress value={progress} max="100"></progress>
              )}
            </div>

            <div>
              <label className="font-bold text-xs text-gray-600">
                Upload Building Permit
              </label>
              <input
                type="file"
                onChange={(e) => handleUpload(e, "Building Permit")}
              />
              {activeUpload === "Building Permit" && (
                <progress value={progress} max="100"></progress>
              )}
            </div>
          </div>
        )}

        {formData.duration > 5 && formData.workPermitType === "minor work" && (
          <div className="text-gray-600 text-xs p-2 ">
            Reminder: Minor works exceeding 5 days is required to pay the
            following bonds:
            <ul className="font-semibold list-disc ml-4">
              <li>Repair Bond: Php 15,000</li>
              <li>Sanitation Bond: Php 10,000</li>
            </ul>
          </div>
        )}

        {formData.workPermitType === "major work" && (
          <div className="text-gray-600 text-xs p-2 ">
            Reminder: Major works bond requirments:
            <ul className="font-semibold list-disc ml-4">
              <li>Renovation Bond: Php 75,000</li>
              <li>Sanitation Bond: Php 10,000</li>
            </ul>
          </div>
        )}

        {formData.workPermitType === "construction" && (
          <div className="text-gray-600 text-xs p-2 ">
            Reminder: Construction bond requirments:
            <ul className="font-semibold list-disc ml-4">
              <li>Construction Bond: Php 100,000</li>
              <li>Sanitation Bond: Php 10,000</li>
              <li>Electrical Distribution Tapping Fee: Php 20,000</li>
              <li>Water System Connection Fee: Php 100,000</li>
            </ul>
          </div>
        )}

        {formData.duration > 5 && formData.workPermitType === "minor work" && (
          <div>
            <label className="font-bold text-xs text-gray-600">
              Upload proof of bond payment
            </label>
            <input
              type="file"
              onChange={(e) => handleUpload(e, "Bond Payment")}
            />
            {activeUpload === "Bond Payment" && (
              <progress value={progress} max="100"></progress>
            )}
          </div>
        )}

        {formData.workPermitType !== "minor work" && (
          <div>
            <label className="font-bold text-xs text-gray-600">
              Upload proof of bond payment
            </label>
            <input
              type="file"
              onChange={(e) => handleUpload(e, "Bond Payment")}
            />
            {activeUpload === "Bond Payment" && (
              <progress value={progress} max="100"></progress>
            )}
          </div>
        )}

        <div className="text-center">
          <button
            className="bg-blue-900 text-white p-2 rounded-lg mt-4 w-full"
            onClick={handleSubmit}
          >
            {isProcessing ? "Processing..." : "Submit"}
          </button>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default WorkPermitUser;
