import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";

function WorkPermits() {
  const [activeFilter, setActiveFilter] = useState("All");
  const [workPermits, setWorkPermits] = useState([]);
  const [filteredWorkPermitApplications, setFilteredWorkPermitApplications] =
    useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchWorkPermits = async () => {
      await getAllDocsSnap("workPermits", (data) => {
        setWorkPermits(data);
        setFilteredWorkPermitApplications(data);
        setIsLoading(false);
      });
    };

    fetchWorkPermits();
  }, []);

  const workPermitApplications = workPermits;

  useEffect(() => {
    if (activeFilter === "All") {
      setFilteredWorkPermitApplications(workPermitApplications);
    } else {
      setFilteredWorkPermitApplications(
        workPermitApplications.filter(
          (application) => application.status === activeFilter
        )
      );
    }
  }, [activeFilter]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const filters = ["All", "Pending", "Approved", "Rejected"];

  const tableHeaders = [
    "Lot No.",
    "Name",
    "Request No.",
    "Status",
    "Date",
    "No. of Days Past",
    "Documents",
    "Description",
    "Remarks",
    "Action Taken",
  ];

  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp);
    return date;
  };

  //compute the number of days past since the request was made
  const computeDaysPast = (dateString) => {
    //convert date string to date object
    const date = new Date(dateString);

    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const newFilteredWorkPermitApplications =
    filteredWorkPermitApplications.filter(
      (application) =>
        application.identifier
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        application.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  console.log(workPermits);

  return (
    <div className="p-2 text-left">
      <div className="text-xl font-bold text-blue-900 text-left">
        Request for Approvals and Work Permits
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start gap-4 mt-2">
          {filters.map((filter, index) => (
            <div
              key={index}
              className={`cursor-pointer rounded-full px-4 py-1 text-sm border-2 border-gray-300 ${
                activeFilter === filter ? "active-pill" : "inactive-pill"
              }`}
              onClick={() => setActiveFilter(filter)}
            >
              {filter}
            </div>
          ))}
        </div>
        <div>
          <button className="blue-button">
            Print
          </button>
        </div>
      </div>
      <div className="mt-4 border-2 border-gray-300 p-2 rounded-lg flex items-center justify-between">
        <div>
          <input
            type="search"
            name="search"
            className="border-2 border-gray-300 px-4 py-2 w-96 rounded-lg"
            placeholder="Search Lott No. or Name"
            onChange={handleSearch}
          />
        </div>
        <div>Filter</div>
      </div>
      <div className="text-xs">
        <table className="w-full mt-4">
          <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index} className="border-2 border-gray-300 p-2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {newFilteredWorkPermitApplications.map((application, index) => (
              <tr key={index} className="border-2 border-gray-300">
                <td className="border-2 border-gray-300 p-2">
                  {application.identifier}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.name}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.id}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.status}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.createdAt}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {computeDaysPast(application.createdAt) - 1}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  <button className="bg-blue-900 text-white px-2 py-1 rounded-lg">
                    View documents
                  </button>
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.description}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.remarks}
                </td>
                <td className="border-2 border-gray-300 p-2 space-x-2">
                  <button className="bg-green-500 text-white px-2 py-1 rounded-lg">
                    Approve
                  </button>
                  <button className="bg-red-500 text-white px-2 py-1 rounded-lg">
                    Decline
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WorkPermits;
