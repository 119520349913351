import React from "react";
import Select from "react-select";

function SelectInput({ options, label, name, onChange, value, ...otherProps }) {
  return (
    <div>
      <label>{label}</label>
      <Select
        {...otherProps}
        options={options}
        name={name}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

export default SelectInput;
