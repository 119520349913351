import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { render } from "@testing-library/react";

export default function AllResidentsTable({
  residents,
  setViewMode,
  setSelectedResident,
}) {
  const columns = [
    { field: "identifier", headerName: "Address", width: 350 },
    { field: "unitOwner", headerName: "Owner", width: 350 },
    { field: "lotSize", headerName: "Lot Size", width: 130 },
    // {
    //   field: "occupants.length()",
    //   headerName: "Occupants",
    //   type: "number",
    //   width: 90,
    // },
    {
      field: "email",
      headerName: "Email Address",
      description: "",
      sortable: false,
      width: 200,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      description: "",
      sortable: false,
      width: 200,
    },
    {
      field: "unitType",
      headerName: "Condition",
      description: "",
      width: 200,
    },
    {
      field: "tenanted",
      headerName: "Tenanted",
      description: "This column has a value getter and is not sortable.",
      width: 200,
    },
  ];

  const rows = residents;

  const paginationModel = { page: 0, pageSize: 10 };

  const handleSelection = (e) => {
    setSelectedResident(e[0]);
  };

  return (
    <Paper sx={{ height: "fit", width: "100%", fontFamily: "Poppins" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: { paginationModel },
          pageSize: 10,
          sorting: {
            sortModel: [{ field: "id", sort: "asc" }],
          },
        }}
        pageSizeOptions={[5, 10]}
        sx={{ border: 0, fontSize: "0.8rem", fontFamily: "Poppins" }}
        onRowSelectionModelChange={handleSelection}
        onRowDoubleClick={() => setViewMode(true)}
      />
    </Paper>
  );
}
