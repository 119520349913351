import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import ProfilePhotoWithDetails from "./ProfilePhotoWithDetails";

function HoaDirectory() {
  return (
    <UserScreenWindow label="HOA Director">
      <div className="font-bold text-blue-900 text-sm text-center mt-8 header">
        HOA Directory
      </div>
      <div className="font-bold text-gray-600 text-sm mt-8 header text-center">
        Tali Beach Hotline Number
      </div>
      <div className="mt-4 text-center">
        <a href="tel:09270368277">0927 036 8277</a>
      </div>

      <div className="font-bold text-gray-600 text-sm header mt-8 text-center">
        Tali Beach Makati Office
      </div>
      <div className="flex flex-col text-center mt-4">
        <a href="tel:09151782646">0915 178 2646</a>
        <a href="tel:0288928015">02 8892 8015</a>
      </div>

      <div className="font-bold text-gray-600 text-sm mt-4 header text-center">
        Admin Maintenance
      </div>
      <div className="flex flex-row items-center justify-center gap-2 mt-8">
        <ProfilePhotoWithDetails
          image={""}
          name="Engr. Arjay Elumbra"
          contactNumber={"0916 108 5814"}
        />
        <ProfilePhotoWithDetails
          image={""}
          name="Engr. Rene Espenida"
          contactNumber={"0930 887 2501"}
        />
      </div>

      <div className="font-bold text-gray-600 text-sm mt-8 header text-center">
        Tali Beach Corporate Security and Safety Coordinator
      </div>
      <ProfilePhotoWithDetails
        name="Mr. Joevanni Cialbo"
        contactNumber="0905 338 7440"
      />
    </UserScreenWindow>
  );
}

export default HoaDirectory;
