import React, { useEffect } from "react";
import {
  getDocData,
  getAllDocs,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import InputBox from "../Dashboard/Components/InputBox";
import toast from "react-hot-toast";
import blankPhoto from "../assets/blankgroupphoto.avif";
import { storage } from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useSelector } from "react-redux";

export default function AddParticpantForm({
  groupChat,
  handleAddParticipants,
  id,
}) {
  const { members, admins = [] } = groupChat;
  const currentUser = useSelector((state) => state.userObject);
  const [users, setUsers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [participants, setParticipants] = React.useState(members);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [adminsList, setAdminsList] = React.useState(admins);
  const [image, setImage] = React.useState("");
  const [uploading, setUploading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    const fetchAllUsers = async () => {
      const users = await getAllDocs("users");
      setUsers(users);
      setIsLoading(false);
    };
    fetchAllUsers();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const membersMap = users.filter((user) => participants.includes(user.id));

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const options = users.map((user) => ({
    value: user.id,
    label: user.displayName,
  }));

  const filteredUsers = users.filter((user) =>
    user.displayName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAdd = (user) => {
    //check if user is already in the group
    if (participants.includes(user.id)) {
      toast.error("User is already in the group");
      return;
    }

    setParticipants([...participants, user.id]);
    setSearchTerm("");
    console.log(participants);
  };

  const handleSave = async () => {
    setIsProcessing(true);
    await setDocData("groupChats", id, {
      members: participants,
      admins: adminsList,
    });
    handleAddParticipants();
    setIsProcessing(false);
    toast.success("Group Settings updated successfully");
  };

  const handleRemove = (user) => {
    if (
      window.confirm(`Are you sure you want to remove ${user.displayName}?`)
    ) {
      const updatedParticipants = participants.filter(
        (participant) => participant !== user.id
      );
      setParticipants(updatedParticipants);
    }
  };

  const handleAddRemoveAdmin = (user) => {
    if (adminsList.includes(user.id)) {
      const updatedAdmins = adminsList.filter((admin) => admin !== user.id);
      setAdminsList(updatedAdmins);
    } else {
      setAdminsList([...adminsList, user.id]);
    }
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `groupChatImages/id/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setUploading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
        setUploading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await setDocData("groupChats", id, { image: downloadURL });
          setUploading(false);
          toast.success("Image uploaded successfully");
        });
      }
    );
  };

  console.log(membersMap);
  console.log(adminsList);

  return (
    <div className="small-form relative">
      <button onClick={handleAddParticipants} className="close-button">
        Cancel
      </button>
      <div className="header pt-8">Add Particpant</div>
      <form action="">
        <input
          className="border border-gray-100 p-2 rounded-lg w-full mt-4"
          placeholder="Search for users"
          type="search"
          onChange={handleSearch}
          value={searchTerm}
        />
      </form>
      {searchTerm.length > 0 ? (
        <div className="absolute z-10 max-h-36 bg-gray-100 w-full p-2 rounded-lg overflow-y-scroll">
          {filteredUsers.map((user) => (
            <div
              key={user.id}
              className="flex items-center gap-2 justify-between hover:bg-gray-200 p-2 rounded-lg cursor-pointer"
            >
              <div onClick={(e) => handleAdd(user)}>{user.displayName}</div>
            </div>
          ))}
        </div>
      ) : null}

      <div className="space-y-2 mt-8">
        <div className="text-blue-900 text-xs header">Current Members</div>
        {membersMap.map((participant, index) => (
          <div
            key={index}
            className="text-sm flex items-center justify-between"
          >
            <div>
              {participant.displayName} -{" "}
              {adminsList.includes(participant.id) ? "Admin" : "Member"}
            </div>
            {admins.includes(currentUser.uid) && (
              <div className="flex items-center gap-2 text-xs ">
                <button
                  className="underline"
                  onClick={() => handleAddRemoveAdmin(participant)}
                >
                  {adminsList.includes(participant.id)
                    ? "Remove Admin"
                    : "Make Admin"}
                </button>
                <button
                  onClick={() => handleRemove(participant)}
                  className="underline"
                >
                  Remove
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-8">
        <div className="header">Group Image</div>
        <div>
          <img
            className="w-20 h-20 rounded-full object-cover"
            src={groupChat.image || blankPhoto}
            alt=""
          />
        </div>
        <div className="flex flex-col text-xs">
          <label className="">Upload Photo</label>
          <input type="file" onChange={handleUpload} />
          {uploading && (
            <div>progress: {parseFloat(progress).toLocaleString()}%</div>
          )}
        </div>
      </div>

      <div className="absolute bottom-0 right-0">
        <button
          disabled={isProcessing}
          onClick={handleSave}
          className="blue-button"
        >
          {isProcessing ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
}
