import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams } from "react-router-dom";
import { getDocData } from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";

function RequestDetails() {
  const { id } = useParams();
  const [request, setRequest] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [files, setFiles] = React.useState([]);

  const currentUser = useSelector((state) => state.userObject);

  React.useEffect(() => {
    const fetchRequest = async () => {
      const data = await getDocData("workPermits", id);

      setRequest(data);
      setIsLoading(false);
    };
    fetchRequest();
  }, []);

  if (isLoading) {
    return (
      <UserScreenWindow label="Request Details">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  return (
    <UserScreenWindow label="Request Details">
      <div className="bg-white rounded-xl p-2 mt-4 text-gray-600 text-xs">
        <div className="text-center text-sm font-bold text-blue-900 mt-8">
          Request Details
        </div>
        <div className="p-2 font-bold text-xs text-gray-600">
          <div>Request ID Number</div>
          <div>{id}</div>
        </div>
        <div className="space-y-1 p-2 ">
          <div className="text-sm font-bold mb-8">
            <div>Work Permit Type: {request.workPermitType}</div>
            <div>House Number: {request.identifier}</div>
            <div>Duration of Activities: {request.duration}</div>
          </div>
          <div>
            <div className="text-xs font-bold text-gray-600">
              Description of Work to be Done
            </div>
            <div>{request.description}</div>
          </div>
          <div>
            <div className="text-xs font-bold text-gray-600">
              Names of Workers
            </div>
            <div>{request.workers}</div>
          </div>
          <div>
            <div className="text-xs font-bold text-gray-600">
              Vehicle Information
            </div>
            <div>{request.vehicle}</div>
          </div>
          <div className="text-xs font-bold text-gray-600">Files Uploaded</div>
          <div className="flex flex-col gap-2 underline ">
            {request.files.map((file, index) => (
              <a href={file.url} target="_blank" key={index}>
                {file.name}
              </a>
            ))}
            {request.files.length === 0 && <div>No files uploaded</div>}
          </div>
          <div className="text-xs font-bold text-gray-600">
            Bond Payment Status
          </div>
          <div>For Validation</div>
          <div className="text-xs font-bold text-gray-600">Status</div>
          <div>Under Review</div>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default RequestDetails;
