import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import Select from "react-select";
import { useState } from "react";
import { storage } from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { setDocData } from "../../utils/firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function ConcernsForm() {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userObject);
  const [concern, setConcern] = useState("");
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [assignedId, setAssignedId] = useState(v4());

  const concernList = [
    "Electricity",
    "Water Supply",
    "Water Leaks",
    "Internet",
    "Violation",
    "Neighbors",
    "Cutting of Trees",
    "Defective Water Meter",
    "Others",
  ];

  const options = concernList.map((concern) => ({
    value: concern,
    label: concern,
  }));

  const handleSelectConcern = (selectedConcern) => {
    setConcern(selectedConcern.value);
    console.log(selectedConcern);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `concerns/${assignedId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setIsUploading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData({ ...formData, image: downloadURL });
          setIsUploading(false);
        });
      }
    );
  };

  const handleUploadVideo = (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `concerns/${assignedId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setIsUploading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData({ ...formData, video: downloadURL });
          setIsUploading(false);
        });
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    //check if concern is selected
    if (!concern) {
      toast.error("Please select a concern");
      setIsProcessing(false);
      return;
    }

    //check if description is entered
    if (!formData.description) {
      toast.error("Please enter a description");
      setIsProcessing(false);
      return;
    }

    //check if either image or video is uploaded
    if (!formData.image && !formData.video) {
      toast.error("Please upload an image or video");
      setIsProcessing(false);
      return;
    }

    const concernData = {
      concern: concern,
      description: formData.description,
      image: formData.image || "",
      video: formData.video || "",
      user: currentUser.email,
      createdBy: currentUser.displayName,
      uid: currentUser.uid,
      status: "pending",
      createdAt: new Date().toLocaleString(),
    };

    await setDocData("concerns", assignedId, concernData);
    toast.success("Concern filed successfully");
    setIsProcessing(false);
    navigate("/user/concerns");
  };

  console.log(formData);

  return (
    <UserScreenWindow label="Concerns">
      <div>
        <div className="header">Concern Filing</div>
        <div>Select concern below</div>
        <div className="">
          <Select
            options={options}
            placeholder="Select Concern"
            onChange={handleSelectConcern}
          />
        </div>

        <div className="mt-8">
          <div>Describe your concern below</div>
          <textarea
            className="w-full rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500 p-2"
            type="text"
            rows={5}
            placeholder="Enter your concern"
            name="description"
            onChange={handleChange}
          />
        </div>

        <div>
          <div className="space-y-4">
            <div>Upload Photo</div>
            <input type="file" accept="image/*" onChange={handleUpload} />

            <div>Upload Video</div>
            <input type="file" accept="video/*" onChange={handleUploadVideo} />
          </div>

          {formData.image ? (
            <img
              src={formData.image}
              className="w-60 h-60 mt-8 object-contain"
              alt=""
            />
          ) : null}
          {isUploading && (
            <div className="flex items-center justify-normal gap-2 text-xs text-gray-600">
              <div>Uploading...</div>
              <div>{parseFloat(progress).toLocaleString()}%</div>
            </div>
          )}
        </div>

        <div className="mt-8 w-full">
          <button
            className="blue-button"
            onClick={handleSubmit}
            disabled={isProcessing}
          >
            {isProcessing ? "Submitting..." : "Submit Concern"}
          </button>
        </div>
      </div>
    </UserScreenWindow>
  );
}
