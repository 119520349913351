import React from 'react'

export default function TenantCard({tenant}) {
  return (
    <div className='bg-white text-gray-600 border border-gray-100 p-2 flex lg:flex-row flex-col lg:items-center items-start justify-normal gap-2 lg:gap-8 flex-wrap rounded-xl hover:border-blue-900 cursor-pointer'>
        <div className='header'>Lot Owner: {tenant.unitOwner}</div>
        <div className='flex items-center gap-2'>
            <strong>Tenant Name:</strong>
            <div>{tenant.firstName}</div>
            <div>{tenant.lastName}</div>
        </div>
        <div>Lot No. {tenant.lot}</div>
        <div><strong>Lease Start</strong>: {tenant.leaseStart}</div>
        <div><strong>Lease End:</strong> {tenant.leaseEnd}</div>
    </div>
  )
}
