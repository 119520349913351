import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams } from "react-router-dom";
import { getDocData } from "../../utils/firebase/Firebase.utils";

export default function ConcernDetails() {
  const { id } = useParams();
  const [concern, setConcern] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchConcern = async () => {
      const concernData = await getDocData("concerns", id);
      setConcern(concernData);
      setIsLoading(false);
    };
    fetchConcern();
  }, []);

  if (isLoading) {
    return (
      <UserScreenWindow label="Concern Details">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const lastEighthChars = id.slice(-8);

  const comments = concern.comments ? concern.comments : [];

  //check if concern.image is video or image
  const isVideo = concern.image ? concern.image.includes("mp4") : false;

  return (
    <UserScreenWindow label="Concern Details">
      <div className="header">Details of Concern</div>
      <div className="space-y-4 text-sm text-gray-600">
        <div className="flex items-center justify-between">
          <div>Reference Number</div>
          <div>{lastEighthChars}</div>
        </div>
        <div className="flex items-center justify-between gap-8">
          <div>Type of Concern</div>
          <div> {concern.concern}</div>
        </div>
        <div className="flex items-center justify-between gap-8">
          <div>Date Filed:</div>
          <div>{concern.createdAt}</div>
        </div>
        <div className="flex items-center justify-between gap-8">
          <div>Status</div>
          <div>{concern.status}</div>
        </div>
        <div className="flex items-center justify-between gap-8">
          <div>Description</div>
          <div>{concern.description}</div>
        </div>
        <div className="header">Image or Video</div>
        <div>
          {concern.image ? (
            <img className="w-60 " src={concern.image} alt="" />
          ) : null}
        </div>

        <div className="">
          <div className="mb-8 header">Comments</div>
          {comments.map((comment, index) => (
            <div
              key={index}
              className="flex flex-col text-left items-start justify-between text-gray-600 bubble"
            >
              <div className="flex capitalize items-center gap-4 font-normal text-xs ">
                <div>{comment.createdBy}</div>
                <div>{comment.createdAt}</div>
              </div>
              <div>{comment.comment}</div>
            </div>
          ))}
        </div>
      </div>
    </UserScreenWindow>
  );
}
